/* src/index.css */
@import 'tailwindcss/base';

.border-b-0-2 {
    border-bottom-width: 0.2px;
}

.bg-primary-05{
    background: rgba(51, 51, 51, 0.02);
}
.text-xxs{
    font-size: 0.6rem;
}
.drop-shadow-calandar{
    filter: drop-shadow(0px 19px 48px rgba(51, 51, 51, 0.15));
}
.login-bg{
    background-image: url('../public/img/login-bg.png');
}

.text-img-heading{
    text-shadow: 0px 4px 7.4px rgba(0, 0, 0, 0.10);

}
@layer base {
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:900;
        src: url("./Fonts/SFProDisplay-Black.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:700;
        src: url("./Fonts/SFProDisplay-Bold.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:600;
        src: url("./Fonts/SFProDisplay-Semibold.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:500;
        src: url("./Fonts/SFProDisplay-Medium.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:400;
        src: url("./Fonts/SFProDisplay-Regular.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:300;
        src: url("./Fonts/SFProDisplay-Light.ttf") format('ttf');
    }
    @font-face {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight:200;
        src: url("./Fonts/SFProDisplay-Thin.ttf") format('ttf');
    }
   
    

    html {
        font-family: "SF Pro Display";
        @apply text-primary
    }

}





@import 'tailwindcss/components';
@import 'tailwindcss/utilities';